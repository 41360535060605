import React from "react"

import "./TransitionPortal.scss"

const TransitionPortal = () => {
  return (
    <svg
      className="transitionPortal"
      id="transitionPortal"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid slice"
    >
      <defs>
        <mask id="hole">
          <circle id="fillCircle" r="0" cx="50" cy="50" fill="white" />
          <circle id="holeCircle" r="0" cx="50" cy="50" fill="black" />
        </mask>
      </defs>
      <rect
        width="100%"
        height="100%"
        className="transitionPortal__background"
        mask="url(#hole)"
        id="transitionBackground"
      />
    </svg>
  )
}

export default TransitionPortal
