import gsap from "gsap"
import React, { useEffect, useRef, useState } from "react"


import "./Preloader.scss"

const Preloader = () => {
  const preloaderRef = useRef()
  const preloaderTitleRef = useRef()
  const copy = "Experience a great horseback adventure"
  const [open, setOpen] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setOpen(false)
    }, 5000)
    const tl = gsap.timeline()
    tl.fromTo(preloaderTitleRef.current.querySelectorAll("span"), {
      y: 50,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
      stagger: 0.033,
      delay: 1,
      duration: 0.5,
      ease: "power1.inOut",
    })
      .to(preloaderRef.current, {
        opacity: 0,
        delay: 0.5,
      })
      .set(preloaderRef.current, {
        display: "none",
      })

  }, [])

  return (
    open && <div className="preloader" ref={preloaderRef}>
      <div className="preloader__title-wrapper">
        <h1 className="preloader__title" ref={preloaderTitleRef}>
          {copy.split(" ").map(function (word, i) {
            return (
              <>
                <span aria-hidden="true" key={i}>
                  {word.split("").map(function (char, index) {
                    return (
                      <span aria-hidden="true" key={`${i}-${index}`}>
                        {char}
                      </span>
                    )
                  })}
                </span>
                <span> </span>
              </>
            )
          })}
        </h1>
      </div>
      <div className="preloader__cta">
        <span> </span>
      </div>
    </div>
  )
}

export default Preloader
