import React from "react"
import Preloader from "../components/Preloader"
import TransitionPortal from "../components/TransitionPortal"

import { LanguageContextProvider } from "../context/LanguageContext"
// import { TextureContextProvider } from "../context/TextureContext"

import { Scroll } from "../scroll/Scroll"

const RootLayout = ({ children }) => {
  return (
    // <TextureContextProvider>
    <LanguageContextProvider>
      <Scroll>
        <Preloader />
        {children}
        <TransitionPortal />
      </Scroll>
    </LanguageContextProvider>

  )
}

export default RootLayout
