exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-de-404-jsx": () => import("./../../../src/pages/de/404.jsx" /* webpackChunkName: "component---src-pages-de-404-jsx" */),
  "component---src-pages-en-404-jsx": () => import("./../../../src/pages/en/404.jsx" /* webpackChunkName: "component---src-pages-en-404-jsx" */),
  "component---src-pages-it-404-jsx": () => import("./../../../src/pages/it/404.jsx" /* webpackChunkName: "component---src-pages-it-404-jsx" */),
  "component---src-templates-cjenik-jsx": () => import("./../../../src/templates/cjenik.jsx" /* webpackChunkName: "component---src-templates-cjenik-jsx" */),
  "component---src-templates-djecji-izleti-jsx": () => import("./../../../src/templates/djecjiIzleti.jsx" /* webpackChunkName: "component---src-templates-djecji-izleti-jsx" */),
  "component---src-templates-djecji-kampovi-jsx": () => import("./../../../src/templates/djecjiKampovi.jsx" /* webpackChunkName: "component---src-templates-djecji-kampovi-jsx" */),
  "component---src-templates-galerija-jsx": () => import("./../../../src/templates/galerija.jsx" /* webpackChunkName: "component---src-templates-galerija-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-izlet-jsx": () => import("./../../../src/templates/izlet.jsx" /* webpackChunkName: "component---src-templates-izlet-jsx" */),
  "component---src-templates-izleti-jsx": () => import("./../../../src/templates/izleti.jsx" /* webpackChunkName: "component---src-templates-izleti-jsx" */),
  "component---src-templates-kontakt-jsx": () => import("./../../../src/templates/kontakt.jsx" /* webpackChunkName: "component---src-templates-kontakt-jsx" */),
  "component---src-templates-o-nama-jsx": () => import("./../../../src/templates/oNama.jsx" /* webpackChunkName: "component---src-templates-o-nama-jsx" */),
  "component---src-templates-pansion-za-konje-jsx": () => import("./../../../src/templates/pansionZaKonje.jsx" /* webpackChunkName: "component---src-templates-pansion-za-konje-jsx" */),
  "component---src-templates-poklon-bon-jsx": () => import("./../../../src/templates/poklonBon.jsx" /* webpackChunkName: "component---src-templates-poklon-bon-jsx" */),
  "component---src-templates-popust-cetvrtkom-jsx": () => import("./../../../src/templates/popustCetvrtkom.jsx" /* webpackChunkName: "component---src-templates-popust-cetvrtkom-jsx" */),
  "component---src-templates-pravila-privatnosti-jsx": () => import("./../../../src/templates/pravilaPrivatnosti.jsx" /* webpackChunkName: "component---src-templates-pravila-privatnosti-jsx" */),
  "component---src-templates-proslava-rodendana-jsx": () => import("./../../../src/templates/proslavaRodendana.jsx" /* webpackChunkName: "component---src-templates-proslava-rodendana-jsx" */),
  "component---src-templates-skola-jahanja-jsx": () => import("./../../../src/templates/skolaJahanja.jsx" /* webpackChunkName: "component---src-templates-skola-jahanja-jsx" */),
  "component---src-templates-team-building-jsx": () => import("./../../../src/templates/teamBuilding.jsx" /* webpackChunkName: "component---src-templates-team-building-jsx" */),
  "component---src-templates-terapijsko-jahanje-jsx": () => import("./../../../src/templates/terapijskoJahanje.jsx" /* webpackChunkName: "component---src-templates-terapijsko-jahanje-jsx" */)
}

