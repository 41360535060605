import React from "react"

import { createContext, useContext, useState } from "react"

const defaultLanguage = "hr"

const LanguageContext = createContext(defaultLanguage)

export const LanguageContextProvider = ({ children }) => {
  const [language, setLanguage] = useState(defaultLanguage)

  const contextValues = {
    language,
    setLanguage,
  }

  return (
    <LanguageContext.Provider value={contextValues}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguageContext = () => {
  const context = useContext(LanguageContext)
  if (context === undefined || context === null) {
    throw new Error(
      `useLanguageContext must be called within LanguageContextProvider`
    )
  }
  return context
}
