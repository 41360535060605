import { useFrame } from "@studio-freight/hamo"
import Lenis from "@studio-freight/lenis"
import React from "react"
import { useRef } from "react"
import { createContext } from "react"
import { useContext } from "react"
import { useEffect } from "react"

const ScrollContext = createContext()

export const Scroll = (props) => {
  const lenis = useRef()

  useEffect(() => {
    const lenisObj = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      direction: "vertical",
      gestureDirection: "vertical",
      smooth: false,
      mouseMultiplier: 1,
      smoothTouch: false,
      touchMultiplier: 2,
      infinite: false,
    })

    lenis.current = lenisObj

    return () => {
      lenis.destroy()

      lenis.current = null
    }
  }, [])

  useFrame((time) => {
    lenis.current?.raf(time)
  }, [])

  return (
    <ScrollContext.Provider value={lenis}>
      {props.children}
    </ScrollContext.Provider>
  )
}

export const useScroll = () => {
  const context = useContext(ScrollContext)
  if (context === undefined || context === null) {
    throw new Error(`useScroll must be called within ScrollContextProvider`)
  }
  return context
}
